export default {
  "message": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人文社科数据集搜素"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2022 北京大学信息管理系"])},
    "about_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台简介"])},
    "index_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收录平台"])},
    "about_platform_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style=\"text-indent:2em;\">大数据时代，科学数据的开放共享受到各方高度重视，“数据爆炸”问题正在显现。随着科学研究转向数据密集型范式，许多学科对数据的需求十分强烈，基于共享的科学数据可支撑高质量研究成果产出。2018年，国务院发布《科学数据管理办法》，明确“开放为常态、不开放为例外”的科学数据共享原则。目前，科学数据正不断积累。据<a href=\"https://www.re3data.org/\">re3data.org</a>统计，全世界至少有2800多个数据仓储；据<a href=\"https://datacite.org/\">DataCite</a>统计，科学数据集至少有800多万个。如何高效地从众多开放数据中发现符合需求的有效数据正成为研究者面临的重要问题。因此，欧美等发达国家对开放科学数据集的统一发现做了大量研究，并推出众多应用平台，如<a href=\"https://datasetsearch.research.google.com/\">谷歌数据集搜索</a>、<a href=\"https://datamed.org/\">DataMed</a>等。</p>\n            <p style=\"text-indent:2em;\">目前，国内对开放科学数据集的统一发现还未得到充分关注，相关理论、方法研究及应用平台建设急需加强。为此，我们构建了<strong>“人文社科数据集搜索”</strong>平台，收录国内外81个重要科学数据仓储平台中的人文社科数据集，以及我国19个省级政府网站开放数据，可帮助人文社科研究人员查找数据，促进科学数据开放、共享和复用，推动我国新文科建设。</p>"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新时间"])},
    "search_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
    "search_detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["题名"])},
    "search_detail_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建者"])},
    "search_detail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
    "search_detail_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
    "search_detail_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出版者"])},
    "search_detail_contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贡献者"])},
    "search_detail_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "search_detail_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
    "search_detail_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格式"])},
    "search_detail_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标识符"])},
    "search_detail_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
    "search_detail_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语种"])},
    "search_detail_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关联"])},
    "search_detail_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范围"])},
    "search_detail_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权限"])},
    "search_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["为您找到", _interpolate(_named("total_hits")), "个数据集 (耗时 ", _interpolate(_named("took_seconds")), "秒)"])}
  }
}