import {createRouter, createWebHashHistory} from 'vue-router'


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component:()=>import('@/views/Home')
        },
        {
            path: '/search',
            name: 'search',
            component:()=>import('@/views/Search')
        },
        {
            path: '/home',
            redirect: '/',
        },
    ]
})

export default router


//https://tiven.cn/p/3747153d/

// export default new Router({
//     // mode可选参数：
//     // hash: 默认为hash, 如果使用hash的话，页面的地址就会加上 # 号就会比较不好看，如我们的地址变成如下：http://localhost:8080/#/
//     // history: 我们使用history的话，那么访问页面的时候就和平常一样，不带井号的；
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes: [
//         homeRoter,
//         searchRoter,
//
//         cinemaRoter,
//         mineRoter,
//         // 重定向：当上面路由都不匹配的情况下，跳转到movie页面
//         {
//             path:'/*',
//             redirect:'/home'
//         }
//     ]
// })
