export default {
  "message": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humanities and Social Sciences Dataset Search (HSSDS)"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2022 Department of Information Management, Peking University"])},
    "about_platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Platform"])},
    "index_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indexed Repositories"])},
    "about_platform_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style=\"text-indent:2em;\">Open government data portals and open research repositories have been launched at different administrative levels over recent years in China. Datasets in these data portals are described following different metadata standards and rarely indexed by major data search systems, e.g., Google Dataset Search. To fill the gap and facilitate the search and retrieval of Chinese government data and open science data from heterogeneous sources, we built an integrated <b>Humanities and Social Science Dataset Search (HSSDSearch)</b> system. The system has indexed datasets from open government data portals in China and major research data repositories from the world, a total of 100 repositories, and more than 2 million datasets. It enables researchers to access datasets from diverse sources in one platform.</p>"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Date"])},
    "search_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail Information"])},
    "search_detail_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "search_detail_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "search_detail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "search_detail_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "search_detail_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
    "search_detail_contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributor"])},
    "search_detail_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "search_detail_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "search_detail_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "search_detail_identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
    "search_detail_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "search_detail_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "search_detail_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
    "search_detail_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage"])},
    "search_detail_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "search_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("total_hits")), " datasets found (takes ", _interpolate(_named("took_seconds")), " second)"])}
  }
}